var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warm-intros" },
    [
      _vm.profiles.length
        ? _c(
            "b-tooltip",
            {
              attrs: {
                label:
                  _vm.$umodel.full_name(_vm.profiles[0]) +
                  " is connnected to " +
                  _vm.$umodel.full_name(_vm.profiles[0].connections[0]) +
                  (_vm.profiles.length > 1
                    ? ", and " + (_vm.profiles.length - 1) + " more connections"
                    : ""),
                type: "is-dark"
              }
            },
            [
              _c("Button", {
                attrs: {
                  text:
                    _vm.profiles.length > 1
                      ? "+" + (_vm.profiles.length - 1)
                      : " ",
                  type: "grey",
                  size: "xs"
                },
                on: {
                  click: function() {
                    return (_vm.showModal = true)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "left",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "warm-intros-trigger" },
                            [
                              _c("Avatar", {
                                staticClass: "warm-intros-trigger-avatar",
                                attrs: {
                                  user: _vm.profiles[0],
                                  "fallback-icon": "user",
                                  size: "s"
                                }
                              }),
                              _vm._l(
                                _vm.profiles[0].connections.slice(0, 2),
                                function(profile) {
                                  return _c("Avatar", {
                                    key: profile.url,
                                    staticClass: "warm-intros-trigger-avatar",
                                    attrs: {
                                      user: profile,
                                      "fallback-icon": "user",
                                      size: "xs"
                                    }
                                  })
                                }
                              )
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2086162119
                )
              })
            ],
            1
          )
        : _c("p", { staticClass: "warm-intros-empty" }, [_vm._v("None")]),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "warm-intros-modal" }, [
            _c(
              "div",
              { staticClass: "warm-intros-modal-header" },
              [
                _vm._v(" Warm introductions "),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.closeModal }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "warm-intros-modal-content" },
              [
                _c("Table", {
                  attrs: {
                    items: _vm.profiles,
                    headers: _vm.tableHeaders,
                    sortable: false,
                    clickable: _vm.profiles.length > 1,
                    "max-height": "40vh",
                    padding: "1.5rem"
                  },
                  on: { select: _vm.handleSelect },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        var header = ref.header
                        return [
                          header.id === "checkbox"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "warm-intros-modal-content-table-checkbox-wrapper"
                                },
                                [
                                  _c("Checkbox", {
                                    staticClass:
                                      "warm-intros-modal-content-table-checkbox",
                                    attrs: {
                                      value: _vm.selection.some(function(s) {
                                        return s.url === item.url
                                      })
                                    }
                                  })
                                ],
                                1
                              )
                            : ["connection", "connection_of"].includes(
                                header.id
                              )
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "warm-intros-modal-content-table-avatars"
                                },
                                _vm._l(
                                  header.id === "connection"
                                    ? [item]
                                    : item.connections,
                                  function(person) {
                                    return _c(
                                      "div",
                                      {
                                        key: person.url,
                                        staticClass:
                                          "warm-intros-modal-content-table-avatar"
                                      },
                                      [
                                        _c("Avatar", {
                                          attrs: {
                                            user: person,
                                            "fallback-icon": "user",
                                            size: "s"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "warm-intros-modal-content-table-avatar-info"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "warm-intros-modal-content-table-avatar-info-name"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$umodel.full_name(
                                                        person
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "\n                        warm-intros-modal-content-table-avatar-info-linkedin\n                      ",
                                                  attrs: {
                                                    src: require("@/assets/logo/linkedin.svg"),
                                                    alt: ""
                                                  },
                                                  on: {
                                                    click: function() {
                                                      return _vm.gotoLinkedIn(
                                                        person
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "warm-intros-modal-content-table-avatar-info-role"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(person.role) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : header.id === "actions"
                            ? _c("Button", {
                                attrs: {
                                  text: "Pitch",
                                  type: "white",
                                  loading:
                                    _vm.pitchLoading &&
                                    _vm.selection.length === 1 &&
                                    _vm.selection[0].url === item.url,
                                  disabled:
                                    _vm.pitchLoading &&
                                    (_vm.selection.length > 1 ||
                                      _vm.selection[0].url !== item.url)
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return function() {
                                      return _vm.pitchFromItem(item)
                                    }.apply(null, arguments)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm.footerVisible
              ? _c(
                  "div",
                  { staticClass: "warm-intros-modal-footer" },
                  [
                    _c("Button", {
                      attrs: {
                        text: "Pitch to selected audience",
                        type: "white",
                        disabled:
                          !_vm.selection.length ||
                          (_vm.pitchLoading && _vm.selection.length === 1),
                        loading: _vm.pitchLoading && _vm.selection.length > 1
                      },
                      on: { click: _vm.handlePitch }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }