<template>
  <div class="warm-intros">
    <b-tooltip
      v-if="profiles.length"
      :label="`${$umodel.full_name(
        profiles[0]
      )} is connnected to ${$umodel.full_name(profiles[0].connections[0])}${
        profiles.length > 1
          ? `, and ${profiles.length - 1} more connections`
          : ''
      }`"
      type="is-dark"
    >
      <Button
        :text="profiles.length > 1 ? `+${profiles.length - 1}` : ' '"
        type="grey"
        size="xs"
        @click="() => (showModal = true)"
      >
        <template #left>
          <div class="warm-intros-trigger">
            <Avatar
              :user="profiles[0]"
              fallback-icon="user"
              class="warm-intros-trigger-avatar"
              size="s"
            />
            <Avatar
              v-for="profile in profiles[0].connections.slice(0, 2)"
              :key="profile.url"
              :user="profile"
              fallback-icon="user"
              class="warm-intros-trigger-avatar"
              size="xs"
            />
          </div>
        </template>
      </Button>
    </b-tooltip>
    <p v-else class="warm-intros-empty">None</p>
    <b-modal v-model="showModal">
      <div class="warm-intros-modal">
        <div class="warm-intros-modal-header">
          Warm introductions
          <Button icon="close" type="grey" size="xs" @click="closeModal" />
        </div>
        <div class="warm-intros-modal-content">
          <Table
            :items="profiles"
            :headers="tableHeaders"
            :sortable="false"
            :clickable="profiles.length > 1"
            max-height="40vh"
            padding="1.5rem"
            @select="handleSelect"
          >
            <template #item="{ item, header }">
              <div
                v-if="header.id === 'checkbox'"
                class="warm-intros-modal-content-table-checkbox-wrapper"
              >
                <Checkbox
                  :value="selection.some((s) => s.url === item.url)"
                  class="warm-intros-modal-content-table-checkbox"
                />
              </div>
              <div
                v-else-if="['connection', 'connection_of'].includes(header.id)"
                class="warm-intros-modal-content-table-avatars"
              >
                <div
                  v-for="person in header.id === 'connection'
                    ? [item]
                    : item.connections"
                  :key="person.url"
                  class="warm-intros-modal-content-table-avatar"
                >
                  <Avatar :user="person" fallback-icon="user" size="s" />
                  <div class="warm-intros-modal-content-table-avatar-info">
                    <div
                      class="warm-intros-modal-content-table-avatar-info-name"
                    >
                      {{ $umodel.full_name(person) }}
                      <img
                        src="@/assets/logo/linkedin.svg"
                        alt=""
                        class="
                          warm-intros-modal-content-table-avatar-info-linkedin
                        "
                        @click="() => gotoLinkedIn(person)"
                      />
                    </div>
                    <p class="warm-intros-modal-content-table-avatar-info-role">
                      {{ person.role }}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                v-else-if="header.id === 'actions'"
                text="Pitch"
                type="white"
                :loading="
                  pitchLoading &&
                  selection.length === 1 &&
                  selection[0].url === item.url
                "
                :disabled="
                  pitchLoading &&
                  (selection.length > 1 || selection[0].url !== item.url)
                "
                @click.native.stop="() => pitchFromItem(item)"
              />
            </template>
          </Table>
        </div>
        <div v-if="footerVisible" class="warm-intros-modal-footer">
          <Button
            text="Pitch to selected audience"
            type="white"
            :disabled="
              !selection.length || (pitchLoading && selection.length === 1)
            "
            :loading="pitchLoading && selection.length > 1"
            @click="handlePitch"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import Table from '@c/library/Table.vue'
import Checkbox from '@c/library/Checkbox.vue'

export default {
  name: 'WarmIntroductions',
  components: {
    Button,
    Avatar,
    Table,
    Checkbox
  },
  props: {
    profiles: {
      type: Array,
      default: () => []
    },
    pitchLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showModal: false,
    selection: [],
    footerVisible: false
  }),
  computed: {
    tableHeaders() {
      return [
        ...(this.profiles.length > 1
          ? [
              {
                id: 'checkbox',
                title: '',
                key: () => ''
              }
            ]
          : []),
        {
          id: 'connection',
          title: 'Connected to',
          key: () => ''
        },
        {
          id: 'connection_of',
          title: 'Connection of',
          key: () => ''
        },
        {
          id: 'actions',
          title: '',
          key: () => ''
        }
      ]
    }
  },
  watch: {
    pitchLoading(val) {
      if (!val) {
        this.closeModal()
      }
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.selection = []
    },
    pitchFromItem(item) {
      this.selection = [item]
      this.handlePitch()
    },
    handleSelect(item) {
      if (this.selection.some((s) => s.url === item.url)) {
        this.selection = this.selection.filter((s) => s.url !== item.url)
      } else {
        this.selection.push(item)
      }
      this.footerVisible = this.selection.length > 0
    },
    handlePitch() {
      this.$emit('pitch', this.selection)
    },
    gotoLinkedIn(item) {
      window.open(item.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.warm-intros {
  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 0.5rem;
    padding-right: 0.5rem;

    &-avatar {
      margin-right: -0.5rem;
    }
  }

  &-modal {
    width: min(65rem, 90vw);
    background: white;
    border-radius: 6px;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      border-bottom: 1px solid rgba(#000, 0.08);
      font-size: 1.5rem;
      font-weight: 600;
    }

    &-content {
      padding: 1.5rem 0;
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      &-table {
        &-checkbox {
          pointer-events: none;
          transform: translateY(-50%);

          &-wrapper {
            height: 100%;
            padding: 1.625rem 0;
          }
        }

        &-avatars {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
        }

        &-avatar {
          min-width: 25rem;
          padding: 0.5rem 0;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(#000, 0.08);
          }

          &-info {
            display: flex;
            flex-flow: column nowrap;

            &-name {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              gap: 0.5rem;
            }

            &-linkedin {
              height: 1.1rem;
              cursor: pointer;
              transition: transform 0.2s ease;

              &:hover {
                transform: scale(1.1);
              }
            }

            &-role {
              color: #60666b;
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 1.5rem;
      border-top: 1px solid rgba(#000, 0.08);
    }
  }

  &-empty {
    padding-left: 1.35rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
