import { Paginator, getClient, getResponseData } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function getProspectsPaginator({
  workspace_id,
  query,
  organisation_id,
  industries,
  headquarters,
  employee_counts,
  is_account,
  ambassadors,
  page_size,
  callback = () => {}
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(organisation_id ? { organisation_id } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(is_account ? { is_account } : {}),
    ...(ambassadors ? { ambassadors } : {})
  }
  const queryParam = {
    ...(page_size ? { page_size } : {})
  }
  const res = await client.post(`/${workspace_id}/prospects/search/`, body, {
    params: queryParam
  })
  callback(res)
  return new Paginator(
    client,
    res,
    () => {},
    undefined,
    url => client.post(url, body)
  )
}

export async function getProspectsPage({
  workspace_id,
  query,
  organisation_id,
  industries,
  headquarters,
  employee_counts,
  is_account,
  ambassadors,
  page_size,
  export_filetype
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(organisation_id ? { organisation_id } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(is_account ? { is_account } : {}),
    ...(ambassadors ? { ambassadors } : {})
  }
  const queryParam = {
    ...(page_size ? { page_size } : {}),
    ...(export_filetype ? { export: export_filetype } : {})
  }
  const res = await client.post(`/${workspace_id}/prospects/search/`, body, {
    params: queryParam
  })
  return getResponseData(res)
}

export async function createProspectsList({
  workspace_id,
  search,
  name,
  assignee_ids,
  fields
}) {
  const body = {
    search,
    name,
    ...(assignee_ids ? { assignee_ids } : {}),
    ...(fields ? { fields } : {})
  }
  const res = await client.post(`/${workspace_id}/prospects/lists/`, body)
  return getResponseData(res)
}

export async function getProspectsLists({ workspace_id }) {
  const res = await client.get(`/${workspace_id}/prospects/lists/`)
  return getResponseData(res)
}

export async function removeProspectsList({ workspace_id, list_id }) {
  const res = await client.delete(
    `/${workspace_id}/prospects/lists/${list_id}/`
  )
  return getResponseData(res)
}

export async function getProspectAccounts({
  workspace_id,
  list_id,
  export_filetype,
  page_size = 20,
  paginated = true
}) {
  const res = await client.get(
    `/${workspace_id}/prospects/lists/${list_id}/accounts/`,
    {
      params: { page_size, ...(export_filetype && { export: export_filetype }) }
    }
  )
  return paginated
    ? new Paginator(
        client,
        res,
        () => {},
        undefined,
        url => client.get(url)
      )
    : getResponseData(res)
}
